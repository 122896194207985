import React from 'react';
import styled, { css } from 'styled-components';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';

import {
  OutlinedDeleteIcon,
  UpArrowLargeIcon,
  DownArrowLargeIcon,
  CancelRoundIcon,
  ChevronLargeIcon,
} from '/imports/generator/ui/assets';
import { PencilIcon } from '/imports/core/ui/assets';

export const mobileElement = (icon, label, click) => {
  return (
    <FlexInner onClick={click}>
      <FlexIconWrapper>{icon}</FlexIconWrapper>
      <FlexDetailWrapper>{label}</FlexDetailWrapper>
    </FlexInner>
  );
};

const BlockItemMobileControls = ({
  expand,
  moveUp,
  moveDown,
  remove,
  onAfterAction,
  t,
  isCollapsed,
  order,
  items,
  type,
}) => {
  const isDragAndDrop = marvelEmitter.getActiveVariant('exp_skills_DD_V2') === 'with_drag';
  const isNewDragAndDrop = marvelEmitter.getActiveVariant('exp_new_other_section_DD_V2') === 'with_drag';
  const BLOCK_WITH_DRAG = ['SOCIAL_LINKS', 'LANGUAGES', 'REFERENCES'];
  const isBoth = marvelEmitter.getActiveVariant('exp_new_other_section_DD_V2') === 'with_both';

  const renderArrow = (direction) => {
    if (direction === 'up') return mobileElement(<UpArrowLargeIcon />, t('move_up'), moveUp);
    else return mobileElement(<DownArrowLargeIcon />, t('move_down'), moveDown);
  };

  const renderArrowSkills = () => {
    return (
      <>
        {order > 0 && !isDragAndDrop && renderArrow('up')}
        {order + 1 < items.length && !isDragAndDrop && renderArrow('down')}
      </>
    );
  };

  const renderArrowDrag = () => {
    return (
      <>
        {order > 0 && !isNewDragAndDrop && renderArrow('up')}
        {order + 1 < items.length && !isNewDragAndDrop && renderArrow('down')}
      </>
    );
  };

  const renderArrowBoth = () => {
    return (
      <>
        {order > 0 && renderArrow('up')}
        {order + 1 < items.length && renderArrow('down')}
      </>
    );
  };

  const renderDragArrow = () => {
    return (
      <>
        {type === 'SKILLS' && <>{renderArrowSkills()}</>}
        {BLOCK_WITH_DRAG.includes(type) && !isBoth && <>{renderArrowDrag()}</>}
        {isBoth && BLOCK_WITH_DRAG.includes(type) && renderArrowBoth()}
        {type !== 'SKILLS' && !BLOCK_WITH_DRAG.includes(type) && renderArrowBoth()}
      </>
    );
  };

  return (
    <FlexWrapper>
      {isCollapsed
        ? mobileElement(<PencilIcon width="24" height="24" />, t('edit'), expand)
        : mobileElement(<ChevronLargeIcon />, t('generator. collapse'), expand)}
      {renderDragArrow()}
      {mobileElement(<OutlinedDeleteIcon width="24" height="24" />, t('remove'), remove)}
      {mobileElement(<CancelRoundIcon />, t('cancel'), onAfterAction ? onAfterAction : null)}
    </FlexWrapper>
  );
};

const FlexDetailWrapper = styled.div`
  width: 85%;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 17px;
  font-weight: 600;
  color: #bfc5e0;
  margin: auto 0;
  text-transform: capitalize;
`;

const FlexIconWrapper = styled.div`
  width: 15%;
  display: flex;
  svg {
    margin: 0 auto;
    fill: #bfc5e0;
    color: #bfc5e0;
  }
`;

const FlexInner = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 24px;
  padding-bottom: 6px;
  cursor: pointer;
`;

export const FlexWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-bottom: 18px;

  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

export default BlockItemMobileControls;
