import BlockItemControls from '../components/BlockItemControls';

import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';

import ControlsModal from '/imports/generator/ui/components/ControlsModal';
import SvgIcon from '/imports/core/ui/atoms/SvgIcon';
import DragDropIcon from 'imports/coaching/ui/assets/DragDropIcon';
import { darken } from 'polished';

import intlHook from 'imports/core/api/useIntl';
import Flex from 'imports/core/ui/atoms/Flex';
import { useRef, useState, useEffect, Fragment } from 'react';
import { useResponsive } from 'imports/core/api/responsiveContext';

const ItemControlsMobileButton = (props) => {
  const { item } = props;

  const [modalOpened, setModalOpened] = useState(false);
  const [tooltipStyle, setTooltipStyle] = useState({});
  const [isClosed, setIsClosed] = useState(false);
  const iconRef = useRef();
  const tooltipRef = useRef();
  const { t } = intlHook();
  const { isMobile } = useResponsive();

  useEffect(() => {
    if (isMobile) {
      updateTooltipPosition();
    }
    setTimeout(() => setIsClosed(true), 10000);
  }, []);

  const toggleModal = () => setModalOpened(!modalOpened);

  const updateTooltipPosition = () => {
    if (tooltipRef.current && iconRef.current) {
      const iconRect = iconRef.current.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();

      const left = -tooltipRect.left + (iconRect.left + 5);

      setTooltipStyle({
        left: `${left}px`,
      });
    }
  };
  const isDragAndDrop = marvelEmitter.getActiveVariant('exp_skills_DD_V2') !== 'with_up_and_down';
  const isNewDragAndDrop = marvelEmitter.getActiveVariant('exp_new_other_section_DD_V2') !== 'with_up_and_down';
  const BLOCK_WITH_DRAG = ['SOCIAL_LINKS', 'LANGUAGES', 'REFERENCES'];

  return (
    <Fragment>
      <Flex alignItems="center">
        {isDragAndDrop && props.type == 'SKILLS' && (
          <Fragment>
            {item.order == 0 && !isClosed && (
              <Tooltip ref={tooltipRef} className="tips">
                <ToastText>{t('tooltip_drag_drop')}</ToastText>
                <PointArrow style={{ ...tooltipStyle }} />
              </Tooltip>
            )}

            <ButtonDrag>
              <DragDropIcon ref={iconRef} />
            </ButtonDrag>
          </Fragment>
        )}
        {isNewDragAndDrop && BLOCK_WITH_DRAG.includes(props.type) && (
          <Fragment>
            {item.order == 0 && !isClosed && (
              <Tooltip ref={tooltipRef} className="tips">
                <ToastText>{t('tooltip_drag_drop')}</ToastText>
                <PointArrow style={{ ...tooltipStyle }} />
              </Tooltip>
            )}

            <ButtonDrag>
              <DragDropIcon ref={iconRef} />
            </ButtonDrag>
          </Fragment>
        )}

        <Button alignItems="center" onClick={toggleModal}>
          <SvgIcon.More width="19px" height="6px" viewBox="0 0 17 4" />
        </Button>
      </Flex>
      <ControlsModal {...props} onClose={toggleModal} noCancel openStateBase open={modalOpened}>
        <BlockItemControls {...props} onAfterAction={toggleModal} />
      </ControlsModal>
    </Fragment>
  );
};

ItemControlsMobileButton.displayName = 'ItemControlsMobileButton';

ItemControlsMobileButton.PropTypesropTypes = {
  isDragging: PropTypes.string,
  item: PropTypes.object,
  showTooltip: PropTypes.string,
};

const Button = styled(Flex)`
  position: relative;
  width: 25px;
  height: 100%;
  svg {
    transform: scale(1.1);
    ${({ theme }) =>
      theme.max('xs')`
      transform: scale(0.9);
  `}
  }
`;

const ButtonDrag = styled(Flex)`
  color: #7171a6;
  margin-inline: 10px;
  &:hover {
    color: ${(p) => darken(0.1, p.theme.colors.primary)} !important;
  }
`;

const ToastText = styled.div`
  color: var(--light-values-white);
  font-size: 14px;
  line-height: 14px;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  text-align: center;
`;

const PointArrow = styled.div`
  position: absolute;
  bottom: -5px;
  margin-right: auto;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--grayscale-n800);
`;

const Tooltip = styled.div`
  position: absolute;
  max-width: 240px;
  align-items: center;
  padding: 12px;
  border-radius: 4px;
  user-select: none;
  bottom: 36px;
  right: 0px;
  background-color: var(--grayscale-n800);
  margin-top: -45px;
  width: max-content;

  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      left: 0px;
      right: unset;
    `};
`;

export default ItemControlsMobileButton;
